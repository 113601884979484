<template>
    <div class="cs-block cs-contact-form-block" :class="styleClass">
        <div class="cs-base-block">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="info-wrapper">
                            <h2 class="cs-title" v-if="data.title">{{ data.title }}</h2>
                            <h3 class="cs-sub-title" v-if="data.subTitle">{{data.subTitle}}</h3>
                            <span class="cs-text" v-if="data.text" v-html="data.text"></span>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <CSForm :data="dataInfo" :id="data.id" :oneColumnForm="true" :themeClass="false"></CSForm>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {computed} from '../libs/common-fn';
    
    export default {
        name: "ContactForm",
        components: {
            CSForm: () => import("../components/CSForm.vue") 
        },
        props: {
            data: {
                type: Object,
            default: () => { },
            }
        },
        data() {
            return {
                dataInfo: {
                    title: this.data.formTitle,
                    text: this.data.formText,
                    buttons: this.data.formButtons,
                    columns: this.data.formColumns,
                },
            };
        },
        methods: {},
        computed: computed('ContactForm')
    }

</script>
<style scoped lang="scss">
    @import "../styles/main.scss";

    .cs-contact-form-block{
        .info-wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media (max-width: 991px) {
                margin-bottom: 15px;
            }
        }
    }

</style>